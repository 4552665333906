/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import {
  Row,
  Col,
  Tabs,
  Input,
  Button,
  Modal,
  Checkbox,
  Table,
  Skeleton,
  Tooltip,
  message,
  Progress,
  Form,
  InputNumber,
} from "antd";
import { ArrowLeftOutlined, SearchOutlined } from "@ant-design/icons";
import shopifyLogo from "./../../assets/Images/shopifyLogo.svg";
import blustreamLogo from "./../../assets/Images/blustreamLogo.svg";
import glowingBulbIcon from "./../../assets/Images/glowingBulbIcon.svg";
import keyboard from "./../../assets/Images/keyboard.svg";
import puzzlePieces from "./../../assets/Images/puzzlePieces.svg";
import pen from "./../../assets/Images/pen.svg";
import chainCircle from "./../../assets/Images/chainCircle.svg";
import successIcon2 from "./../../assets/Images/successIcon2.svg";
import smallTriTechLogo from "./../../assets/Images/smallTriTechLogo.svg";
import deleteIcon4 from "./../../assets/Images/deleteIcon4.svg";
import brokenChain from "./../../assets/Images/brokenChain.svg";
import {
  createShopifyProductMapping,
  updateShopifyProductMapping,
  deleteShopifyProductMapping,
} from "./../../graphql/mutations";

import Joyride from "react-joyride";

import "./productmapping.css";
import { API, graphqlOperation } from "aws-amplify";
import { useSelector } from "react-redux";
import { getProduct } from "apiService/RESTApi";
import { listShopifyProductMappings } from "./../../graphql/queries";
import sdk from "sdk/Accounts";
import { onUpdateTriTechProfile } from "graphql/subscriptions";

const { TabPane } = Tabs;
const { Search } = Input;

const ProductMapping = () => {
  const history = useHistory();

  const [triTechMappingForm] = Form.useForm();
  const { roAdmin, groups, accountDetails, shopifyProfileId } = useSelector(
    (state) => state.loginReducer
  );
  const [
    shopifyProductMappingModalVisible,
    setShopifyProductMappingModalVisible,
  ] = useState(false);
  const [
    shopifyMappingSuccessModalVisible,
    setshopifyMappingSuccessModalVisible,
  ] = useState(false);
  const [
    shopifyMappingStatusModalVisible,
    setShopifyMappingStatusModalVisible,
  ] = useState(false);
  const [isAlreadyMapped, setIsAlreadyMapped] = useState(false);

  const [shopifyTableData, setShopifyTableData] = useState([]);
  const [allBlustreamProducts, setAllBlustreamProducts] = useState([]);
  const [selectedBlustreamProductId, setSelectedBlustreamProductId] =
    useState("");
  const [allShopifyProducts, setAllShopifyProducts] = useState([]);
  const [filteredShopifyProductList, setFilteredShopifyProductList] = useState(
    []
  );
  const [selectedShopifyProductIds, setSelectedShopifyProductIds] = useState(
    []
  );
  const [mappedProductsList, setMappedProductsList] = useState([]);
  const [mappedShopifyProductIdsList, setMappedShopifyProductIdsList] =
    useState([]);
  const [currentMappedValues, setCurrentMappedValues] = useState([]);
  const [updateMappedValue, setUpdateMappedValue] = useState([]);
  const [tableloading, setTableloading] = useState(false);
  const [tableMaploading, settableMaploading] = useState(false);
  const [allchecked, setAllChecked] = useState(false);
  const [isTourRunning, setIsTourRunning] = useState(false);
  const [joyrideStepIndex, setJoyrideStepIndex] = useState(0);
  const [showLearnHow, setShowLearnHow] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState("1");

  // tritech states
  const [showTriTechMappingModal, setShowTriTechMappingModal] = useState(false);
  const [triTechProductMappingList, setTriTechProductMappingList] = useState(
    []
  );
  const [triTechProductMappingTableData, setTriTechProductMappingTableData] =
    useState([]);
  const [selectedRowForTriTech, setSelectedRowForTriTech] = useState({});
  const [triTechMappingListTableData, setTriTechMappingListTableData] =
    useState([]);
  const [triTechMappingLoader, setTriTechMappingLoader] = useState(false);
  const [isTritechConnected, setIsTritechConnected] = useState(false);

  const shopifyTableColumns = [
    {
      title: (
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <img
            src={blustreamLogo}
            alt=""
            style={{ height: "18px", width: "18px" }}
          />
          <span
            style={{
              fontFamily: "Hellix",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "16px",
              lineHeight: "134.19%",
              textTransform: " capitalize",
              color: "#124173",
            }}
          >
            Blustream Products
          </span>
        </div>
      ),
      dataIndex: "blustreamProduct",
      key: "blustreamProduct",
      width: "40%",
    },
    {
      title: (
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <img
            src={shopifyLogo}
            alt=""
            style={{ height: "18px", width: "18px" }}
          />
          <span
            style={{
              fontFamily: "Hellix",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "16px",
              lineHeight: "134.19%",
              textTransform: " capitalize",
              color: "#124173",
            }}
          >
            Shopify Products
          </span>
        </div>
      ),
      dataIndex: "shopifyProduct",
      key: "shopifyProduct",
      width: "50%",
    },
    {
      title: "",
      dataIndex: "edit",
      key: "edit",
      width: "10%",
    },
  ];

  const triTechTableColumns = [
    {
      title: (
        <div className="tritech-table-header-wrapper">
          <img
            src={blustreamLogo}
            className="tritech-table-blustream-header-logo"
            alt=""
          />
          <span className="tritech-table-blustream-header-text">
            Blustream Products
          </span>
        </div>
      ),
      dataIndex: "blustreamProduct",
      key: "blustreamProduct",
      render: (field, row) => {
        return (
          <>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <img
                src={blustreamLogo}
                alt=""
                style={{ height: "36px", width: "36px" }}
              />
              <span
                style={{
                  fontFamily: "Hellix",
                  fontStyle: "normal",
                  fontWeight: "500",
                  fontSize: "18px",
                  lineHeight: "20x",
                  textAlign: "center",
                  color: "#252525",
                }}
              >
                {row?.productName}
              </span>
            </div>
          </>
        );
      },
      width: "40%",
    },
    {
      title: (
        <div className="tritech-table-header-wrapper">
          <img
            src={smallTriTechLogo}
            alt=""
            className="tritech-table-tritech-header-logo"
          />
          <span className="tritech-table-tritech-header-text">
            Tri-Tech Products
          </span>
        </div>
      ),
      dataIndex: "tritechMappedArray",
      key: "tritechMappedArray",
      render: (field, row) => {
        return (
          <>
            {row?.tritechMappedArray?.length > 0 ? (
              <div style={{ display: "flex", gap: "24px" }}>
                <div className="tritech-category-wrapper">
                  <div className="tritech-category-title">Category</div>
                  {row?.tritechMappedArray?.map((item) => (
                    <div className="tritech-category-number">
                      {item?.split("-")[0]}
                    </div>
                  ))}
                </div>
                <div className="tritech-sub-category-wrapper">
                  <div className="tritech-sub-category-title">Subcategory</div>
                  {row?.tritechMappedArray?.map((item) => (
                    <div className="tritech-sub-category-number">
                      {item?.split("-")[1]}
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <img
                  src={chainCircle}
                  alt=""
                  style={{ height: "40px", width: "40px" }}
                />
                <span
                  style={{
                    fontFamily: "Hellix",
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "16px",
                    lineHeight: "19px",
                    textAlign: "center",
                    color: "#929292",
                  }}
                >
                  No product mapped yet!
                </span>
              </div>
            )}
          </>
        );
      },
      width: "50%",
    },
    {
      title: "",
      dataIndex: "edit",
      key: "edit",
      render: (field, row) => {
        return (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <img
                id={row?.productId}
                src={pen}
                alt=""
                onClick={() => {
                  setSelectedRowForTriTech(row);
                  const tableData = row?.tritechMappedArray?.map((item) => {
                    return {
                      item: item,
                      category: item?.split("-")[0],
                      sub_category: item?.split("-")[1],
                      delete: "",
                    };
                  });
                  setTriTechMappingListTableData(tableData);
                  setShowTriTechMappingModal(true);
                }}
              />
            </div>
          </>
        );
      },
      width: "10%",
    },
  ];

  const triTechMappingListColumns = [
    {
      title: "Category number",
      dataIndex: "category",
      key: "category",
      render: (field, row) => field,
      width: "43.75%",
    },
    {
      title: "Sub Category number",
      dataIndex: "sub_category",
      key: "sub_category",
      render: (field, row) => field,
      width: "43.75%",
    },
    {
      title: "",
      dataIndex: "delete",
      key: "delete",
      render: (field, row) => {
        return (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <img
                src={deleteIcon4}
                alt=""
                onClick={() => {
                  setTriTechMappingListTableData((previous) => {
                    const index = previous.indexOf(row);
                    if (index !== -1) {
                      previous.splice(index, 1);
                    }

                    return [...previous];
                  });
                }}
              />
            </div>
          </>
        );
      },
      width: "12.5%",
    },
  ];

  const mappedColumns = [
    {
      title: "",
      dataIndex: "klaviyoProduct",
      key: "klaviyoProduct",
      width: "80%",
    },
    {
      title: "",
      dataIndex: "success",
      key: "success",
      width: "20%",
    },
  ];

  const mappingStatusColumns = [
    {
      title: (
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <img
            src={shopifyLogo}
            alt=""
            style={{ height: "18px", width: "18px" }}
          />
          <span
            style={{
              fontFamily: "Hellix",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "16px",
              lineHeight: "134.19%",
              textTransform: " capitalize",
              color: "#124173",
            }}
          >
            Products
          </span>
        </div>
      ),
      dataIndex: "klaviyoProduct",
      key: "klaviyoProduct",
      width: "60%",
    },
    {
      title: (
        <span
          style={{
            fontFamily: "Hellix",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "16px",
            lineHeight: "134.19%",
            textTransform: " capitalize",
            color: "#124173",
          }}
        >
          Status
        </span>
      ),
      dataIndex: "status",
      key: "status",
      width: "40%",
    },
  ];

  const selectedShopifyProduct = (value) => {
    let ids;
    if (selectedShopifyProductIds?.includes(value)) {
      ids = selectedShopifyProductIds?.filter((id) => id !== value);
    } else {
      ids = [...selectedShopifyProductIds, value];
    }
    setSelectedShopifyProductIds(ids);
  };

  //for listing mapped shopify products
  const listAllMappedProducts = async () => {
    setTableloading(true);
    try {
      const response = await API.graphql(
        graphqlOperation(listShopifyProductMappings, {
          filter: {
            account: {
              eq: accountDetails?.id,
            },
          },
        })
      );
      const res = response?.data?.listShopifyProductMappings?.items;
      //due to variants introduction format of backend is changed from array to object.
      //we have changed format of response back to array in below if block so that everything work as it is and we don't have to build the frontend from scratch for shopify mapping.
      let formatedResponse = [];
      if (res?.length > 0) {
        formatedResponse = res.map((mappedObject) => {
          const shopifyProductVariants = JSON.parse(
            mappedObject?.shopify_product_variants
          );
          const shopifyProductIds = Object.keys(shopifyProductVariants);
          const shopifyProductDetails =
            mappedObject?.shopify_product_details.map((item) =>
              JSON.parse(item)
            );
          const shopifyProducts = shopifyProductIds.map((productID) => {
            const productData = shopifyProductDetails?.find(
              (item) => item?.id?.toString() === productID
            );
            const variants = productData?.variants?.filter((variant) =>
              shopifyProductVariants[productID]?.includes(
                variant?.id?.toString()
              )
            );
            productData["variants"] = variants;
            return productData;
          });
          const mappedProductDetails = generateProductVariants(shopifyProducts);
          const convertedIds = [];
          shopifyProductIds?.forEach((productID) => {
            shopifyProductVariants[productID]?.forEach((variant) => {
              convertedIds.push(`${productID}-${variant}`);
            });
          });
          mappedObject["shopify_product_variants"] = convertedIds;
          mappedObject["shopify_product_details"] = mappedProductDetails;
          return mappedObject;
        });
      }
      setShowLearnHow(formatedResponse?.length <= 0);
      let temp = [];
      let productFilterData = formatedResponse.filter(
        (item) =>
          item.blustream_product_id.toString() === selectedBlustreamProductId
      );
      if (productFilterData?.length > 0) {
        productFilterData.forEach((el) => {
          el?.shopify_product_details.forEach((item, index) => {
            temp.push({
              key: index,
              klaviyoProduct: (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <img
                    src={item?.image?.src}
                    alt="productImage"
                    style={{
                      height: "40px",
                      width: "40px",
                      borderRadius: "50%",
                    }}
                  />
                  <span style={{ display: "flex", flexDirection: "column" }}>
                    <span className="shopify-product-name">{item?.title}</span>
                    {item?.varient_title !== "Default Title" && (
                      <span className="product-varient-details">
                        ({item?.varient_title})
                      </span>
                    )}
                  </span>
                </div>
              ),
              success: (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={successIcon2}
                    alt="successIcon"
                    style={{ height: "18px", width: "18px" }}
                  />
                </div>
              ),
            });
          });
        });
      }
      setMappedProductsList(temp);
      await getBlustreamProducts(formatedResponse);
      setMappedShopifyProductIdsList(formatedResponse);
      setTableloading(false);
    } catch (error) {
      setTableloading(false);
      console.log(error);
    }
  };

  const getBlustreamProducts = async (allProduct) => {
    const blustreamProductsData = await getProduct(groups[0]);
    if (
      blustreamProductsData.response.data &&
      blustreamProductsData.response.data.length > 0
    ) {
      let specificProductFields = blustreamProductsData.response.data.map(
        (product) => ({
          productName: product?.name,
          productId: product?.productId,
          customerId: product?.customerDto?.customerId,
          externalUuid: product?.customerDto?.externalUuid,
        })
      );

      setAllBlustreamProducts(specificProductFields);

      setShopifyTableData(
        specificProductFields?.map((product, index) => {
          return {
            key: product?.productId,
            blustreamProduct: (
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <img
                  src={blustreamLogo}
                  alt=""
                  style={{ height: "36px", width: "36px" }}
                />
                <span
                  style={{
                    fontFamily: "Hellix",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "18px",
                    lineHeight: "20x",
                    textAlign: "center",
                    color: "#252525",
                  }}
                >
                  {product?.productName}
                </span>
              </div>
            ),
            shopifyProduct: (
              <>
                {allProduct.some(
                  (item) => item?.blustream_product_id === product?.productId
                ) ? (
                  allProduct
                    .filter(
                      (item) =>
                        item?.blustream_product_id === product?.productId
                    )[0]
                    ?.shopify_product_details?.map((el, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            marginBottom: 10,
                          }}
                        >
                          <img
                            src={el?.image?.src}
                            alt=""
                            style={{
                              height: "40px",
                              width: "40px",
                              borderRadius: "50%",
                            }}
                          />
                          <span
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span className="shopify-product-name">
                              {el?.title}
                            </span>
                            {el?.varient_title !== "Default Title" && (
                              <span className="product-varient-details">
                                ({el?.varient_title})
                              </span>
                            )}
                          </span>
                        </div>
                      );
                    })
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <img
                      src={chainCircle}
                      alt=""
                      style={{ height: "40px", width: "40px" }}
                    />
                    <span
                      style={{
                        fontFamily: "Hellix",
                        fontStyle: "normal",
                        fontWeight: "600",
                        fontSize: "16px",
                        lineHeight: "19px",
                        textAlign: "center",
                        color: "#929292",
                      }}
                    >
                      No product mapped yet!
                    </span>
                  </div>
                )}
              </>
            ),
            edit: (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  className={`my-first-step-${index} pen-pointer ${
                    roAdmin ? "disable-pointer-events" : ""
                  }`}
                  id={product?.productId}
                  src={pen}
                  alt=""
                  disabled={true}
                  onClick={(event) => {
                    if (
                      allProduct.filter(
                        (el) =>
                          el?.blustream_product_id.toString() ===
                          event.target.id.toString()
                      )?.length
                    ) {
                      let integerIDs = allProduct.filter(
                        (el) =>
                          el?.blustream_product_id.toString() ===
                          event.target.id.toString()
                      )[0]?.shopify_product_variants;
                      setSelectedShopifyProductIds(integerIDs);
                      setIsAlreadyMapped(true);
                    } else {
                      setSelectedShopifyProductIds([]);
                      setIsAlreadyMapped(false);
                    }
                    setCurrentMappedValues([]);
                    setShopifyProductMappingModalVisible(true);
                    setSelectedBlustreamProductId(event.target.id);
                    setAllChecked(false);
                    setTimeout(() => {
                      setJoyrideStepIndex(1);
                    }, 400);
                  }}
                />
              </div>
            ),
          };
        })
      );
    }
  };
  const getInitialValues = async () => {
    setTableloading(true);
    try {
      let apiName = "blustreamREST";
      let path = "/shopify-intergration/shopify/products";
      let body = {
        accountId: accountDetails?.id,
      };

      const response = await API.post(apiName, path, { body: body });
      const shopifyProducts = generateProductVariants(response?.products);
      setAllShopifyProducts(shopifyProducts);
      structureData(shopifyProducts);
    } catch (error) {
      console.log(error);
    }
    await listAllMappedProducts();
  };

  //generating product varients as a separate products
  const generateProductVariants = (productsData) => {
    let shopifyProducts = [];
    productsData?.forEach((productData) => {
      productData?.variants?.forEach((variant) => {
        const { id, title, image_id } = variant;
        const varientObj = {
          id: productData?.id + "-" + id,
          product_id: productData?.id,
          title: productData?.title,
          product_type: productData?.product_type,
          varient_id: id,
          varient_title: title,
          varient_image_id: image_id ? image_id : null,
          image: productData?.image,
        };
        shopifyProducts.push(varientObj);
      });
    });
    return shopifyProducts;
  };

  //stucture products category wise & sorting categories and products
  const structureData = async (response) => {
    let grouped_products = {};

    response?.forEach((product) => {
      let product_type = product?.product_type || "other";

      if (!grouped_products[product_type]) {
        grouped_products[product_type] = [];
      }

      grouped_products[product_type].push({
        id: product?.id,
        product_id: product?.product_id,
        title: product?.title,
        product_type: product_type,
        varient_id: product?.varient_id,
        varient_title: product?.varient_title,
        varient_image_id: product?.varient_image_id
          ? product?.varient_image_id
          : null,
        image: product?.image,
      });
    });
    const result = Object.entries(grouped_products)?.map(([key, value]) => ({
      type: key,
      products: value,
    }));
    result.forEach((item) => {
      item?.products?.sort((a, b) => {
        const titleA = a.title.toUpperCase();
        const titleB = b.title.toUpperCase();

        if (titleA < titleB) {
          return -1;
        }

        if (titleA > titleB) {
          return 1;
        }

        return 0;
      });
    });
    result.sort((a, b) => {
      const typeA = a.type.toUpperCase();
      const typeB = b.type.toUpperCase();

      if (typeA < typeB) {
        return -1;
      }

      if (typeA > typeB) {
        return 1;
      }

      return 0;
    });
    setFilteredShopifyProductList(result);
  };

  //due to variants introduction format of backend is changed from array to object.
  //to transform array back to object for storing in backend.
  const transformArrayToObject = (arr) => {
    const resultObject = {};

    arr.forEach((item) => {
      const [key, value] = item.split("-");

      if (resultObject[key]) {
        resultObject[key].push(value);
      } else {
        resultObject[key] = [value];
      }
    });
    return resultObject;
  };

  const filterShopifyProductList = (event) => {
    const list = allShopifyProducts?.filter((product) =>
      product?.title.toLowerCase().includes(event.toLowerCase()?.trimStart())
    );
    structureData(list);
  };

  //for select all shopify products functionality
  const selectAllShopifyProduct = (value) => {
    const temp = [];
    if (value) {
      mappedShopifyProductIdsList.map((item) => {
        if (
          selectedBlustreamProductId !== item?.blustream_product_id.toString()
        ) {
          temp.push(...item?.shopify_product_variants);
        }
        return item;
      });
      let selectedIds = allShopifyProducts?.filter(
        (product) => !temp.includes(product?.id.toString())
      );
      let filterIds = selectedIds.map((id) => id?.id);
      setSelectedShopifyProductIds(filterIds);
      setCurrentMappedValues(filterIds);
    } else {
      setSelectedShopifyProductIds([]);
      setCurrentMappedValues([]);
    }
  };

  //for mapping shopify products
  const createShopifyProductMappingCustom = async () => {
    settableMaploading(true);
    if (selectedShopifyProductIds?.length > 0) {
      const transformedObject = transformArrayToObject(
        selectedShopifyProductIds
      );
      try {
        let response = await API.graphql(
          graphqlOperation(createShopifyProductMapping, {
            input: {
              account: groups[0],
              blustream_product_id: selectedBlustreamProductId,
              shopify_product_variants: JSON.stringify(transformedObject),
            },
          })
        );
        response = response.data.createShopifyProductMapping;
        await listAllMappedProducts();
        setshopifyMappingSuccessModalVisible(true);
        setShopifyProductMappingModalVisible(false);
        settableMaploading(false);
        return response;
      } catch (error) {
        settableMaploading(false);
        console.log(error);
      }
    } else {
      message.warning(
        "Please select at least one shopify product to map with blustream product"
      );
      settableMaploading(false);
    }
  };
  //for updating selected shopify product
  const updateShopifyProductMappingCustom = async () => {
    setTableloading(true);
    let filterData = mappedShopifyProductIdsList.filter(
      (el) => el.blustream_product_id.toString() === selectedBlustreamProductId
    );

    const transformedObject = transformArrayToObject(selectedShopifyProductIds);
    if (selectedShopifyProductIds?.length > 0) {
      try {
        await API.graphql(
          graphqlOperation(updateShopifyProductMapping, {
            input: {
              id: filterData[0]?.id,
              account: groups[0],
              blustream_product_id: selectedBlustreamProductId,
              shopify_product_variants: JSON.stringify(transformedObject),
            },
          })
        );
        await listAllMappedProducts();
        setTableloading(false);
      } catch (error) {
        setTableloading(false);
        console.log(error);
      }
    } else {
      await API.graphql(
        graphqlOperation(deleteShopifyProductMapping, {
          input: {
            id: filterData[0]?.id,
          },
        })
      )
        .then(async (res) => {
          await listAllMappedProducts();
          setTableloading(false);
        })
        .catch((error) => {
          setTableloading(false);
          console.log(error);
        });
    }
  };

  //disabled checkbox for already mapped products
  const disabledMappedProduct = (id) => {
    let disable = false;
    mappedShopifyProductIdsList.map((item) => {
      item?.shopify_product_variants.map((el) => {
        if (
          el === id.toString() &&
          selectedBlustreamProductId !== item?.blustream_product_id.toString()
        ) {
          disable = true;
          return el;
        }
        return item;
      });
    });
    return disable;
  };

  const listTriTechProductMappings = async () => {
    const response = await sdk.listTriTechMappings(accountDetails?.id);
    setTriTechProductMappingList(response);
  };

  const addTriTechMapping = async () => {
    await sdk.addTriTechMapping(
      selectedRowForTriTech?.account,
      selectedRowForTriTech?.productId,
      triTechMappingListTableData?.map((item) => item?.item)
    );
    await listTriTechProductMappings();
    setShowTriTechMappingModal(false);
  };

  const updateTriTechMapping = async () => {
    await sdk.updateTriTechMapping(
      selectedRowForTriTech?.mappingId,
      selectedRowForTriTech?.productId,
      triTechMappingListTableData?.map((item) => item?.item)
    );
    await listTriTechProductMappings();
    setShowTriTechMappingModal(false);
  };

  const deleteTriTechMapping = async () => {
    await sdk.deleteTriTechMapping(selectedRowForTriTech?.mappingId);
    await listTriTechProductMappings();
    setShowTriTechMappingModal(false);
  };

  //checking array equality
  const arraysEqual = (a, b) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
    a.sort();
    b.sort();
    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  };

  const getConnectedTriTechProfile = async () => {
    await sdk
      .getTriTech(accountDetails?.id)
      .then((res) => {
        const tritechProfile = res?.data?.TriTechProfileByAccountId?.items?.[0];
        if (tritechProfile) {
          setIsTritechConnected(tritechProfile?.enabled);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const updateTriTechProfileSubscription = API.graphql(
      graphqlOperation(onUpdateTriTechProfile)
    ).subscribe({
      next: () => {
        getConnectedTriTechProfile();
      },
      error: (error) => console.warn(error),
    });
    getInitialValues();
    getConnectedTriTechProfile();
    listTriTechProductMappings();
    listAllMappedProducts();

    return () => {
      updateTriTechProfileSubscription?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (allBlustreamProducts?.length > 0) {
      const triTechProductMappingData = allBlustreamProducts?.map(
        (blustreamProduct) => {
          const mappedObject = triTechProductMappingList?.find(
            (item) => item?.blustream_product_id === blustreamProduct?.productId
          );
          if (mappedObject) {
            return {
              mappingId: mappedObject?.id,
              blustreamProduct: blustreamProduct?.productId,
              customerId: blustreamProduct?.customerId,
              edit: "",
              productId: blustreamProduct?.productId,
              productName: blustreamProduct?.productName,
              account: mappedObject?.account,
              tritechMappedArray: mappedObject?.tritech_product_categories,
            };
          } else {
            return {
              mappingId: "",
              blustreamProduct: blustreamProduct?.productId,
              customerId: blustreamProduct?.customerId,
              edit: "",
              productId: blustreamProduct?.productId,
              productName: blustreamProduct?.productName,
              account: blustreamProduct?.externalUuid,
              tritechMappedArray: [],
            };
          }
        }
      );
      setTriTechProductMappingTableData(triTechProductMappingData);
    }
  }, [allBlustreamProducts, triTechProductMappingList]);

  return (
    <div className="main-product-mapping-container">
      <ArrowLeftOutlined
        className="back-arrow"
        onClick={() => history.push("/product")}
      />
      <Row justify="center" style={{ paddingTop: "20px" }}>
        <Col span={18}>
          <div className="product-mapping-header">
            <div className="left-container">
              <img src={puzzlePieces} alt="" />
              <div className="header-title-container">
                <div className="header-title">Map products with blustream</div>
                <p className="sub-header-title">
                  Automatically import products purchased via your ecommerce
                  platform as Blustream products
                </p>
              </div>
            </div>
            <div className="right-container">
              <div className="buttons-container">
                {showLearnHow &&
                allShopifyProducts?.length > 0 &&
                activeTabKey === "1" ? (
                  <Button
                    className="standard-button secondary-orange-button"
                    onClick={() => {
                      setIsTourRunning(true);
                      setJoyrideStepIndex(0);
                    }}
                    disabled={tableloading || roAdmin}
                  >
                    Learn how
                  </Button>
                ) : null}
                {allShopifyProducts?.length > 0 && activeTabKey === "1" ? (
                  <Button
                    className="standard-button primary-dark-blue-button"
                    onClick={async () => {
                      await getInitialValues();
                      message.success(
                        "All the latest shopify products fetched successfully!"
                      );
                    }}
                    disabled={tableloading || roAdmin}
                  >
                    Sync Now
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        </Col>
        <Col span={18} className="product-mapping-tabs">
          <Tabs
            activeKey={activeTabKey}
            onChange={(key) => setActiveTabKey(key)}
            defaultActiveKey="1"
            centered
          >
            <TabPane tab={<span className="tab-title">Shopify</span>} key="1">
              {tableloading ? (
                <div style={{ background: "#fff", padding: 20 }}>
                  <Skeleton active paragraph={{ rows: 7 }} />{" "}
                </div>
              ) : allShopifyProducts?.length > 0 &&
                shopifyProfileId?.shopifyProfileId ? (
                <>
                  <Table
                    className="mapped-products-table"
                    columns={shopifyTableColumns}
                    dataSource={shopifyTableData}
                    pagination={{ showSizeChanger: true }}
                  />

                  <Joyride
                    hideCloseButton
                    disableScrolling
                    run={isTourRunning}
                    showSkipButton
                    stepIndex={joyrideStepIndex}
                    hideBackButton
                    disableCloseOnEsc
                    disableOverlayClose
                    steps={[
                      {
                        target: ".my-first-step-0",
                        content: (
                          <div className="tour-steps-content">
                            <div className="text-center">Step 1</div>
                            <div>
                              <Progress
                                className="progrees-bar"
                                percent={33}
                                status="active"
                              />
                            </div>
                            <div
                              style={{
                                textAlign: "start",
                                marginTop: "10px",
                                marginBottom: "12px",
                              }}
                            >
                              Click on edit icon to map product with Blustream
                            </div>
                            <div style={{ display: "flex" }}>
                              <Button
                                onClick={() => setIsTourRunning(false)}
                                className="standard-button tertiary-button"
                              >
                                Skip
                              </Button>
                            </div>
                          </div>
                        ),
                        disableBeacon: true,
                        spotlightClicks: true,
                      },
                      {
                        target: ".my-second-step-0-0",
                        content: (
                          <div className="tour-steps-content">
                            <div className="text-center">Step 2</div>
                            <div>
                              <Progress
                                className="progrees-bar"
                                percent={66}
                                status="active"
                              />
                            </div>
                            <div
                              style={{
                                textAlign: "start",
                                marginTop: "10px",
                                marginBottom: "12px",
                              }}
                            >
                              Select this product to map
                            </div>
                            <div style={{ display: "flex" }}>
                              <Button
                                onClick={() => setIsTourRunning(false)}
                                className="standard-button tertiary-button"
                              >
                                Skip
                              </Button>
                            </div>
                          </div>
                        ),
                        disableBeacon: true,
                        spotlightClicks: true,
                      },
                      {
                        target: ".my-third-step",
                        content: (
                          <div className="tour-steps-content">
                            <div className="text-center">Step 3</div>
                            <div>
                              <Progress
                                className="progrees-bar"
                                percent={100}
                                status="active"
                              />
                            </div>
                            <div
                              style={{
                                textAlign: "start",
                                marginTop: "10px",
                                marginBottom: "12px",
                              }}
                            >
                              Click to map selected products with blustream
                            </div>
                            <div style={{ display: "flex" }}>
                              <Button
                                onClick={() => setIsTourRunning(false)}
                                className="standard-button tertiary-button"
                              >
                                Skip
                              </Button>
                            </div>
                          </div>
                        ),
                        disableBeacon: true,
                        spotlightClicks: true,
                      },
                    ]}
                    styles={{
                      options: {
                        zIndex: 10000,
                        backgroundColor: "#24405E",
                        arrowColor: "#24405E",
                        textColor: "white",
                        width: "300px",
                      },
                    }}
                  />
                </>
              ) : (
                <div className="shopify-mapping-placeholder">
                  <img src={brokenChain} alt="brokenChain" />
                  <p className="mapping-placeholder-text">
                    Your Blustream account is not connected with Shopify. Please
                    connect it from the ‘Integrations tab’.
                  </p>
                </div>
              )}
            </TabPane>
            <TabPane tab={<span className="tab-title">Tri-Tech</span>} key="2">
              {tableloading ? (
                <div style={{ background: "#fff", padding: 20 }}>
                  <Skeleton active paragraph={{ rows: 7 }} />{" "}
                </div>
              ) : isTritechConnected ? (
                <Table
                  className="tritech-mapped-products-table"
                  columns={triTechTableColumns}
                  dataSource={triTechProductMappingTableData}
                  pagination={{ showSizeChanger: true }}
                />
              ) : (
                <div className="shopify-mapping-placeholder">
                  <img src={brokenChain} alt="brokenChain" />
                  <p className="mapping-placeholder-text">
                    Your Blustream account is not connected with Tri-Tech.
                    Please connect it from the ‘Integrations tab’.
                  </p>
                </div>
              )}
            </TabPane>
          </Tabs>
        </Col>
      </Row>
      <Modal
        width={795}
        className="product-mapping-modal"
        visible={shopifyProductMappingModalVisible}
        title={
          <div className="product-mapping-modal-header-wrapper">
            <div className="blustream-product-details">
              <img className="product-image" src={keyboard} alt="keyboard" />
              <span className="product-name">
                Map with{" "}
                {
                  allBlustreamProducts.filter(
                    (el) =>
                      el.productId.toString() === selectedBlustreamProductId
                  )?.[0]?.productName
                }
              </span>
              <img
                className="blustream-logo"
                src={blustreamLogo}
                alt="blustreamLogo"
              />
            </div>
            <div className="blustream-product-details">
              <Button
                className="standard-button primary-light-blue-button my-third-step"
                type="secondary"
                onClick={() => {
                  if (isAlreadyMapped) {
                    let updateValue = [];
                    let alreadySelected = [];
                    mappedShopifyProductIdsList
                      .filter(
                        (el) =>
                          el.blustream_product_id.toString() ===
                          selectedBlustreamProductId
                      )[0]
                      ?.shopify_product_details.forEach((item) => {
                        alreadySelected.push(item?.id);
                        updateValue.push({
                          key: item?.id,
                          klaviyoProduct: (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <img
                                src={item?.image?.src}
                                alt="productImage"
                                style={{
                                  height: "40px",
                                  width: "40px",
                                  borderRadius: "50%",
                                }}
                              />
                              <span
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span className="shopify-product-name">
                                  {item?.title}
                                </span>
                                {item?.varient_title !== "Default Title" && (
                                  <span className="product-varient-details">
                                    ({item?.varient_title})
                                  </span>
                                )}
                              </span>
                            </div>
                          ),
                          status: (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                fontFamily: "Hellix",
                                fontWeight: " 500",
                                fontSize: "18px",
                                lineHeight: "22px",
                                textAlign: "center",
                                color: "#F5786D",
                              }}
                            >
                              {selectedShopifyProductIds?.includes(item?.id)
                                ? "Unchanged"
                                : "Removing"}
                            </div>
                          ),
                        });
                      });
                    currentMappedValues.forEach((cm) => {
                      allShopifyProducts.forEach((i) => {
                        if (cm === i.id && !alreadySelected.includes(cm)) {
                          updateValue.push({
                            key: i.id,
                            klaviyoProduct: (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                <img
                                  src={i?.image?.src}
                                  alt="productImage"
                                  style={{
                                    height: "40px",
                                    width: "40px",
                                    borderRadius: "50%",
                                  }}
                                />
                                <span
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <span className="shopify-product-name">
                                    {i?.title}
                                  </span>
                                  {i?.varient_title !== "Default Title" && (
                                    <span className="product-varient-details">
                                      ({i?.varient_title})
                                    </span>
                                  )}
                                </span>
                              </div>
                            ),
                            status: (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  fontFamily: "Hellix",
                                  fontWeight: " 500",
                                  fontSize: "18px",
                                  lineHeight: "22px",
                                  textAlign: "center",
                                  color: "#3B84D1",
                                }}
                              >
                                Adding
                              </div>
                            ),
                          });
                        }
                      });
                    });
                    setUpdateMappedValue(updateValue);

                    setShopifyMappingStatusModalVisible(true);
                    setShopifyProductMappingModalVisible(false);
                  } else {
                    createShopifyProductMappingCustom();
                  }
                  setIsTourRunning(false);
                  setJoyrideStepIndex(0);
                }}
                loading={tableMaploading}
              >
                Map now
              </Button>
            </div>
          </div>
        }
        onOk={() => {
          setShopifyProductMappingModalVisible(false);
          setJoyrideStepIndex(0);
        }}
        onCancel={() => {
          setShopifyProductMappingModalVisible(false);
          setJoyrideStepIndex(0);
        }}
        footer={null}
        centered
      >
        <Row justify="center">
          <Col style={{ marginTop: 10 }}>
            {mappedShopifyProductIdsList.filter(
              (el) => el?.shopify_product_variants?.length > 0
            )?.length ? (
              <span className="info-msg">
                * Uncheck already mapped products to map it with new Blustream
                product *
              </span>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="klaviyo-product-search-container">
              <div className="logo-container">
                <img
                  className="klaviyo-logo"
                  src={shopifyLogo}
                  alt=""
                  style={{ height: "18px", width: "18px" }}
                />
              </div>
              <Search
                className="klaviyo-search standard-searchbox"
                prefix={
                  <SearchOutlined className="standard-searchbox-prefix" />
                }
                placeholder="Search Shopify products"
                style={{ height: "42px" }}
                onChange={(event) =>
                  filterShopifyProductList(event.target.value)
                }
              />

              <Checkbox
                className="select-all"
                disabled={
                  mappedShopifyProductIdsList.filter(
                    (el) =>
                      el?.shopify_product_variants?.length ===
                        allShopifyProducts?.length &&
                      selectedBlustreamProductId !==
                        el?.blustream_product_id.toString()
                  )?.length > 0
                    ? true
                    : false
                }
                checked={
                  allchecked ||
                  selectedShopifyProductIds?.length ===
                    allShopifyProducts?.length
                }
                onChange={(event) => {
                  selectAllShopifyProduct(event.target.checked);
                  setAllChecked(event.target.checked);
                }}
              >
                Select all
              </Checkbox>
            </div>
          </Col>
        </Row>
        <Row className="shopify-products-main-container">
          {filteredShopifyProductList?.map((typeObject, firstIndex) => {
            return (
              <Col span={24} style={{ backgroundColor: "#FBFBFB" }}>
                <div className="product-type-title">{typeObject?.type}</div>

                <Row style={{ margin: "0px 18px" }}>
                  {typeObject?.products?.map((product, index) => {
                    return (
                      <Col
                        className="shopify-product-container"
                        span={24}
                        xs={24}
                        sm={12}
                        xl={12}
                        xxl={8}
                      >
                        <Checkbox
                          className={`my-second-step-${firstIndex}-${index}`}
                          disabled={disabledMappedProduct(product?.id)}
                          value={product?.id}
                          onChange={(event) => {
                            if (event.target.checked) {
                              setCurrentMappedValues([
                                ...currentMappedValues,
                                event.target.value,
                              ]);
                              setSelectedShopifyProductIds([
                                ...selectedShopifyProductIds,
                                product?.id,
                              ]);
                            } else {
                              setCurrentMappedValues(
                                currentMappedValues.filter(
                                  (item) => item !== event.target.value
                                )
                              );
                              setSelectedShopifyProductIds(
                                selectedShopifyProductIds.filter(
                                  (item) => item !== product?.id
                                )
                              );
                            }

                            setJoyrideStepIndex(2);
                            selectedShopifyProduct(event.target.value);
                          }}
                          checked={selectedShopifyProductIds?.includes(
                            product?.id
                          )}
                        />
                        <div className="shopify-product">
                          <img
                            className="shopify-product-image"
                            src={product?.image?.src}
                            alt=""
                          />
                          <span
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <span className="shopify-product-name">
                              {product?.title}
                            </span>
                            {product?.varient_title !== "Default Title" && (
                              <span className="product-varient-details">
                                ({product?.varient_title})
                              </span>
                            )}
                          </span>

                          {mappedShopifyProductIdsList.map((item) => {
                            return item?.shopify_product_variants?.map((el) => {
                              return (
                                el === product?.id.toString() && (
                                  <Tooltip
                                    title={
                                      <span>
                                        Mapped with{" "}
                                        {
                                          allBlustreamProducts.filter(
                                            (i) =>
                                              i?.productId ===
                                              item?.blustream_product_id
                                          )?.[0]?.productName
                                        }
                                      </span>
                                    }
                                  >
                                    <img
                                      src={blustreamLogo}
                                      alt=""
                                      style={{ height: "12px", width: "12px" }}
                                    />
                                  </Tooltip>
                                )
                              );
                            });
                          })}
                        </div>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            );
          })}
        </Row>
      </Modal>
      <Modal
        className="product-mapping-success-modal"
        visible={shopifyMappingSuccessModalVisible}
        onCancel={() => setshopifyMappingSuccessModalVisible(false)}
        onOk={() => setshopifyMappingSuccessModalVisible(false)}
        footer={null}
        centered
      >
        <div className="product-mapping-success-modal-content">
          <div
            className="showing-success-wrapper"
            style={{
              display: "flex",
              gap: "11px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img className="success-icon" src={successIcon2} alt="" />
            <p className="success-text">Mapped successfully!</p>
          </div>
          <div className="product-name-container">
            <span className="product-name">
              {
                allBlustreamProducts.filter(
                  (el) => el.productId.toString() === selectedBlustreamProductId
                )?.[0]?.productName
              }
            </span>
            <img className="blustream-logo" src={blustreamLogo} alt="" />
          </div>
          <div style={{ width: "100%" }}>
            {tableloading ? (
              <div style={{ background: "#fff", padding: 20 }}>
                <Skeleton active paragraph={{ rows: 7 }} />{" "}
              </div>
            ) : (
              <Table
                className="plateforms-mapped-products"
                showHeader={false}
                columns={mappedColumns}
                dataSource={mappedProductsList}
                pagination={false}
                scroll={{ y: 300 }}
              />
            )}
          </div>
          <div className="success-modal-container">
            <Button
              className="standard-button primary-orange-button"
              type="primary"
              onClick={() => {
                setshopifyMappingSuccessModalVisible(false);
              }}
            >
              Ok, Thank you!
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        className="mapping-status-modal"
        visible={shopifyMappingStatusModalVisible}
        onCancel={() => setShopifyMappingStatusModalVisible(false)}
        onOk={() => setShopifyMappingStatusModalVisible(false)}
        footer={null}
        centered
      >
        <div className="mapping-status-modal-content">
          <img src={glowingBulbIcon} alt="" />
          <div className="product-name-container">
            <span className="product-name">
              {
                allBlustreamProducts.filter(
                  (el) => el.productId.toString() === selectedBlustreamProductId
                )?.[0]?.productName
              }
            </span>
            <img className="blustream-logo" src={blustreamLogo} alt="" />
          </div>
          <Table
            className="plateforms-mapped-products"
            columns={mappingStatusColumns}
            dataSource={updateMappedValue}
            pagination={false}
            scroll={{ y: 300 }}
          />
          <div style={{ marginTop: "20px", display: "flex", gap: "10px" }}>
            <Button
              className="standard-button tertiary-button"
              onClick={() => {
                setShopifyMappingStatusModalVisible(false);
                setShopifyProductMappingModalVisible(true);
              }}
            >
              Go back
            </Button>
            <Button
              className="standard-button primary-light-blue-button"
              onClick={() => {
                updateShopifyProductMappingCustom();
                setShopifyMappingStatusModalVisible(false);
                setshopifyMappingSuccessModalVisible(true);
              }}
              loading={tableloading}
            >
              Map now
            </Button>
          </div>
        </div>
      </Modal>

      {/* tritech modals */}
      {/* tritech mapping modal */}
      <Modal
        centered
        visible={showTriTechMappingModal}
        footer={null}
        className="tritech-mapping-modal"
        title={
          <div className="tritech-mapping-modal-header-wrapper">
            <div className="blustream-product-details">
              <img className="product-image" src={keyboard} alt="keyboard" />
              <span className="product-name">
                Map with {selectedRowForTriTech?.productName}
              </span>
              <img
                className="blustream-logo"
                src={blustreamLogo}
                alt="blustreamLogo"
              />
            </div>
            <div className="blustream-product-details">
              <Button
                className="standard-button primary-light-blue-button"
                type="secondary"
                loading={triTechMappingLoader}
                onClick={async () => {
                  setTriTechMappingLoader(true);
                  try {
                    if (selectedRowForTriTech?.mappingId?.length > 0) {
                      if (
                        selectedRowForTriTech?.tritechMappedArray?.length ===
                        triTechMappingListTableData?.map((item) => item?.item)
                          ?.length
                      ) {
                        if (
                          arraysEqual(
                            selectedRowForTriTech?.tritechMappedArray,
                            triTechMappingListTableData?.map(
                              (item) => item?.item
                            )
                          )
                        ) {
                          message.error("Update the mapping to map!");
                        } else {
                          if (triTechMappingListTableData?.length > 0) {
                            await updateTriTechMapping();
                          } else {
                            await deleteTriTechMapping();
                          }
                        }
                      } else {
                        if (triTechMappingListTableData?.length > 0) {
                          await updateTriTechMapping();
                        } else {
                          await deleteTriTechMapping();
                        }
                      }
                    } else {
                      if (triTechMappingListTableData?.length > 0) {
                        await addTriTechMapping();
                      } else {
                        message.error("Add product details to map!");
                      }
                    }
                  } catch (error) {
                    console.log(error);
                  } finally {
                    setTriTechMappingLoader(false);
                  }
                }}
              >
                Map now
              </Button>
            </div>
          </div>
        }
        onCancel={() => {
          setShowTriTechMappingModal(false);
          triTechMappingForm?.resetFields();
        }}
      >
        <div className="tritech-mapping-modal-content-wrapper">
          <Form
            layout="vertical"
            form={triTechMappingForm}
            requiredMark={false}
            onFinish={(data) => {
              const alreadyExist = triTechMappingListTableData?.find(
                (item) =>
                  item?.item === `${data?.category}-${data?.sub_category}`
              );
              if (alreadyExist) {
                message.error("Product is already exist!");
              } else {
                setTriTechMappingListTableData((previous) => {
                  return [
                    ...previous,
                    {
                      item: `${data?.category}-${data?.sub_category}`,
                      category: data?.category,
                      sub_category: data?.sub_category,
                      delete: "",
                    },
                  ];
                });
                triTechMappingForm.resetFields();
              }
            }}
          >
            <Row
              gutter={[14, 0]}
              style={{ padding: "20px 18px", margin: "0px" }}
            >
              <Col>
                <Form.Item
                  name="category"
                  label="Enter Category Number"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter the category Number!",
                    },
                  ]}
                >
                  <InputNumber className="standard-input" min={0} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="sub_category"
                  label="Enter Sub Category Number"
                  rules={[
                    {
                      required: true,
                      message: "Please Enter the sub category Number!",
                    },
                  ]}
                >
                  <InputNumber className="standard-input" min={0} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item style={{ marginTop: "22px" }}>
                  <Button
                    className="standard-button secondary-dark-blue-button"
                    htmlType="submit"
                  >
                    Add
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Table
            className="tritech-mapping-list-table"
            columns={triTechMappingListColumns}
            dataSource={triTechMappingListTableData}
            pagination={false}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ProductMapping;
